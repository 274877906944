<template>
  <div>
    <el-form>
      <el-form-item label="群id">
        <el-input v-model="group_id" placeholder="群id"></el-input>
      </el-form-item>
      <el-form-item label="群名">
        <el-input v-model="group_name" placeholder="群名"></el-input>
      </el-form-item>
      <el-form-item label="用户">
        <el-input v-model="list" placeholder="用户"></el-input>
      </el-form-item>
    </el-form>

    <el-button type="primary" @click="createAccount">创建账户</el-button>
    <el-button type="primary" @click="createGroup">创建群组</el-button>
    <el-button type="primary" @click="delGroup">删除群组</el-button>
    <el-button type="primary" @click="addGroupMember">添加群成员</el-button>
    <el-button type="primary" @click="delGroupMember">删除群成员</el-button>
    <el-button type="primary" @click="allUser">导入全部用户</el-button>
    <el-button type="primary" @click="allCoach">导入全部教练</el-button>
    <el-button type="primary" @click="setFamily">setFamily</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      group_id: "",
      group_name: "",
      list: "",
    };
  },

  mounted() {},

  methods: {
    setFamily() {
      this.$get("user/family/setFamily");
    },
    createAccount() {
      let url = "user/tim/createAccount";
      let data = {
        user_id: "11241",
        name: "popy",
        faceUrl: "",
      };
      this.$post(url, data);
    },
    createGroup() {
      let url = "user/tim/createGroup";
      let data = {
        group_name: this.group_name,
        memberList: this.list.split(","),
      };
      this.$post(url, data);
    },
    delGroup() {
      let url = "user/tim/delGroup";
      let data = {
        group_id: this.group_id,
      };
      this.$post(url, data);
    },
    addGroupMember() {
      let url = "user/tim/addGroupMember";
      let data = {
        group_id: this.group_id,
        memberList: this.list.split(","),
      };
      this.$post(url, data);
    },
    delGroupMember() {
      let url = "user/tim/delGroupMember";
      let data = {
        group_id: this.group_id,
        memberList: this.list.split(","),
      };
      this.$post(url, data);
    },
    allUser() {
      let url = "user/tim/importAllUser";

      this.$get(url);
    },
    allCoach() {
      let url = "user/tim/importAllCoach";

      this.$get(url);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>